import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useGlobalState } from '../gloabl';
import {Grid} from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Error() {
    const [state, dispatch] = useGlobalState();
    const {error} = state;
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        dispatch({error:null})
        setOpen(false);
    }

    const getErrorDescription = () =>{
        if (error && error.ErrorDescription && error.data)
        {
               return (<>
                   <Grid container >
                       <Grid item xs={3}>
                           <Typography sx={{ fontWeight: 600 }}>
                                   Description:
                           </Typography>
                       </Grid>
                       <Grid item xs={9}>
                           <Typography>
                                    {error.ErrorDescription || ''}
                           </Typography>
                       </Grid>
                       <Grid item xs={3}>
                           <Typography sx={{ fontWeight: 600 }}>
                                   Code:
                           </Typography>
                       </Grid>
                       <Grid item xs={9}>
                           <Typography>
                                   {error.data.code || ''}
                           </Typography>
                       </Grid>
                       <Grid item xs={3}>
                           <Typography sx={{ fontWeight: 600 }}>
                                   Message:
                           </Typography>
                       </Grid>
                       <Grid item xs={9}>
                           <Typography >
                               {error.data.message || ''}
                           </Typography>
                       </Grid>
                   </Grid>
            </>);
        }
        else
        {
            return <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {error || ''}
            </Typography>
        }
    }
    useEffect(() =>{
        if(error !== null){
            setOpen(true)
        }
        else{
            setOpen(false)
        }
    },[error])

    return (
        <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h4" sx={{color:"red"}} >
                Error
            </Typography>
                {
                    getErrorDescription()
                }

            </Box>
        </Modal>
        </div>
    );
}