import React from 'react'
import { CssBaseline, AppBar, Toolbar, Typography } from '@mui/material'
import {makeStyles} from '@mui/styles'
import { Link } from "react-router-dom";
import Authenticate from './Authenticate';
import { useGlobalState } from '../gloabl';

const useStyles = makeStyles((theme) => ({
    navlinks: {
      marginLeft: theme.spacing(20),
      display: "flex",
    },
   logo: {
      flexGrow: "1",
      cursor: "pointer",
    },
    toolbar: {
        justifyContent: "space-between",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "15px",
      marginLeft: theme.spacing(2),
      "&:hover": {
        color: "blue",
        borderBottom: "1px solid white",
      },
    },
  }))

const NavBar = () => {
    const classes = useStyles();
    const [state, dispatch] = useGlobalState();
    const authenticated = state.user !== null ? true: false;
    return(
        <div>
        <AppBar position="static">
            <CssBaseline />
            <Toolbar className={classes.toolbar}>
                <Typography variant="title" color="inherit">
                MATRIX SSH-PKI
                </Typography>
                <div className={classes.navlinks}>
                { authenticated && <Link to="/issue" className={classes.link}> Issue </Link> }
                { authenticated && <Link to="/renew" className={classes.link}> Renew </Link> }
                { authenticated && <Link to="/revoke" className={classes.link}> Revoke </Link> }
                { authenticated && <Link to="/report" className={classes.link}> Report </Link> }
                </div>
                <div className={classes.navlinks}>
                <Authenticate />
                </div>
            </Toolbar>
        </AppBar>
        </div>
    )
}
export default NavBar;
