export const downloadCertificateForPublicKeyFile = (certificateData, publicKeyFile) => {
    certificateData = atob (certificateData);
    const element = document.createElement("a");
    const file = new Blob([certificateData],
        {type: "text/plain;charset=utf-8"});
    element.href = URL.createObjectURL(file);
    element.download = getCertFileName(publicKeyFile);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}


export const getCertFileName = (publicKeyFile) => {
    if (publicKeyFile?.name) {
        if (publicKeyFile.name.indexOf(".pub") !== -1)
            return publicKeyFile.name.slice(0, publicKeyFile.name.indexOf(".pub") ) +"-cert.pub";
        else
            return publicKeyFile.name + "-cert.pub";
    } else {
        return "";
    }
}
