import React, { useEffect, useState } from 'react';
import {Container, CssBaseline, Box, Grid, Button, Grow} from '@mui/material'
import { useGlobalState } from '../gloabl';
import {FileUpload} from "./FileUpload";
import { requestRenewCertificate} from "./support/ApiService";
import {downloadCertificateForPublicKeyFile, getCertFileName} from "./support/Downloader";
import {SignatureInfo} from "./SignatureInfo";

export default function RenewCertificates() {
  const [state, dispatch] = useGlobalState();
  const [publicKey, setPublicKey] = useState("");
  const [publicKeyFile, setPublicKeyFile] = useState("");
  const [oldCertificate, setOldCertificate] = useState("");
  const [oldCertificateFile, setOldCertificateFile] = useState("");
  const [signatureNewKeyFile, setSignatureNewKeyFile] = useState("");
  const [signatureNewKey, setSignatureNewKey] = useState("");
  const [signatureOldKeyFile, setSignatureOldKeyFile] = useState("");
  const [signatureOldKey, setSignatureOldKey] = useState("");

  const submit = () =>{
    if (!publicKey || !oldCertificate || !signatureOldKey || !signatureNewKey){
      dispatch({"error":"All fields must be filled"})
      return true;
    }

    dispatch({"message": "Fetching the certificate"});
    requestRenewCertificate(publicKey,oldCertificate, signatureOldKey, signatureNewKey ).then((r)=> {
      dispatch({"message":null});
      if ( !r.certificateData){
        dispatch({"error": `Error fetching the certificate ${r}`});
      }
      downloadCertificateForPublicKeyFile(r.certificateData, publicKeyFile)

    }).catch( e => {
      dispatch({"message":null});
      let error = e.response;
      error.ErrorDescription = "Error fetching the certificate";
      dispatch({"error": error});
    });
  }

  const publicKeyFileUploadProps = {
    accept: '.pub',
    id: 'RenewPublicKeyFileUpload',
    selectedFile: publicKeyFile,
    onFileUpload: (file, fileContent) => {
      setPublicKeyFile (file);
      setPublicKey(fileContent);
    }
  }

  const oldCertificateFileUploadProps = {
    accept: '.pub',
    id: 'RenewOldCertificateFileUpload',
    selectedFile: oldCertificateFile,
    onFileUpload: (file, fileContent) => {
      setOldCertificateFile (file);
      setOldCertificate(fileContent);
    }
  }

  const signatureWithNewKeyFileUploadProps = {
    accept: '.sig',
    id: 'RenewSignatureNewKeyFileUpload',
    selectedFile: signatureNewKeyFile,
    onFileUpload: (file, fileContent) => {
      setSignatureNewKeyFile (file);
      setSignatureNewKey(fileContent);
    }
  }

  const signatureWithOldKeyFileUploadProps = {
    accept: '.sig',
    id: 'RenewSignatureOldKeyFileUpload',
    selectedFile: signatureOldKeyFile,
    onFileUpload: (file, fileContent) => {
      setSignatureOldKeyFile (file);
      setSignatureOldKey(fileContent);
    }
  }


  return (
    <>
    <h3>Renew certificates</h3>
    <CssBaseline />
      <Container maxWidth="lg">
       <Grow  in={true} timeout={400}>
       <Box sx={{ flexGrow: 1 , marginTop: "10px"}}>
        <Grid container spacing={2} style={{textAlign:"left"}}>
          <Grid item xs={3}>
            New public key
          </Grid>
          <Grid item xs={9} >
            <FileUpload {...publicKeyFileUploadProps}/>
          </Grid>

          <Grid item xs={3}>
            Old certificate
          </Grid>
          <Grid item xs={9} >
            <FileUpload {...oldCertificateFileUploadProps}/>
          </Grid>
          <Grid item xs={3}>
            Signature with old key
            <SignatureInfo title={'Signature with old private key'} content={'The following string must be signed with your old private key:\r\npublic key file in base64 format, followed by a "#"-character and then the certificate in base64 format.\r\nA signature file can be created with ssh-keygen as in the following example:\r\necho -n "$(base64 -w 0 <public-key-file>)#$(base64 -w 0 <certificate-file>)" | ssh-keygen -Y sign -f <private-key-file> -n renew-ssh-key.dormakaba.com > output.sig '} />
          </Grid>
          <Grid item xs={9} >
            <FileUpload {...signatureWithOldKeyFileUploadProps}/>
          </Grid>
          <Grid item xs={3}>
            Signature with new key
            <SignatureInfo title={'Signature with new private key'} content={'The following string must be signed with your new private key:\r\npublic key file in base64 format, followed by a "#"-character and then the certificate in base64 format.\r\nA signature file can be created with ssh-keygen as in the following example:\r\necho -n "$(base64 -w 0 <public-key-file>)#$(base64 -w 0 <certificate-file>)" | ssh-keygen -Y sign -f <private-key-file> -n renew-ssh-key.dormakaba.com > output.sig '} />
          </Grid>
          <Grid item xs={9} >
            <FileUpload {...signatureWithNewKeyFileUploadProps}/>
          </Grid>

          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Button variant="contained" onClick={() => submit()} >
              Generate certificate {getCertFileName(publicKeyFile)}
            </Button>
          </Grid>
      </Grid>
     </Box>
     </Grow>
      </Container>
    </>
  );
}

