import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import configuration from "./config.json";

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: configuration.userPoolId,
    userPoolWebClientId: configuration.userPoolWebClientId,
    mandatorySignIn: false,

    oauth: {
      // see https://stackoverflow.com/a/64348070
      domain: configuration.domain || "auth.dev.ra.porthos.io",
      scope: ["email", "profile", "openid", "ssh-pki.porthos/ISSUE", "ssh-pki.porthos/RENEW",
        "ssh-pki.porthos/REPORT", "ssh-pki.porthos/REVOKE", "ssh-pki.porthos/GETREVOCATIONS"],
      redirectSignIn: configuration.redirectSignIn,
      redirectSignOut: configuration.redirectSignOut,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "pki",
        endpoint: configuration.endpoint,
      },
    ],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
