import React from 'react'
import { Dialog, DialogTitle, Typography} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const SignatureInfo = ({ title, content }) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = (value) => {
        setOpen(false);
    };
    return (

        <>
            <span title={'Click for further details...'}>
              <InfoOutlinedIcon  style={{verticalAlign:"bottom", marginLeft:"5px"}} onClick={e=>setOpen(true)} />
            </span>
            <Dialog onClose={handleClose} open={open}
                    PaperProps={{ sx: { maxWidth: "revert", width: 1200 } }}>
                <DialogTitle>{title}</DialogTitle>
                <Typography marginLeft={3} marginRight={3}>
                    <pre style={{ fontFamily: 'inherit' }}>
                        {content}
                    </pre>
                </Typography>
            </Dialog>

        </>
    )
}
