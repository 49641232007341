import {API, Auth} from "aws-amplify";
import {Buffer} from "buffer";

export function toBase64(toEncode) {
    const buffer = Buffer.from(toEncode);
    return buffer.toString("base64");
}

async function postMethod(path, data) {
    const myInit = {
        headers: { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: data,
    };
    return await API.post("pki", path, myInit);
}

async function deleteMethod(path, data) {
    const myInit = {
        headers: { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: data,
    };
    return await API.del("pki", path, myInit);
}

async function getMethod(path) {
    const myInit = {
        headers: { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
    };
    return await API.get("pki", path, myInit);
}

export async function unrevokeCertificateByPrincipal(type, searchValue) {
    return unrevokeCertificate(type, "principal", searchValue);
}

export async function unrevokeCertificateByPublicKey(type, searchValue) {
    return unrevokeCertificate(type, "publicKey", searchValue);
}

async function unrevokeCertificate(type, searchBy, searchValue) {
    const path=`certificates/type/${type}/revocations`;
    const data = { };
    data[searchBy] = searchValue;

    return deleteMethod(path, data);
}

export async function getReport(type, principal) {
    const path = `/certificates/type/${type}/principal/${principal}`;
    return getMethod(path);
}

export async function revokeCertificateByPrincipal(type, searchValue) {
    return revokeCertificate(type, "principal", searchValue);
}

export async function revokeCertificateByPublicKey(type, searchValue) {
    return revokeCertificate(type, "publicKey", searchValue);
}

async function revokeCertificate(type, searchBy, searchValue) {
    const path=`certificates/type/${type}/revocations`;
    const data = {};
    data[searchBy] = searchValue;

    return postMethod(path, data);
}

export async function requestCertificate(type, publicKey, principal, signature) {
    const path=`certificates/type/${type}`;
    const data = {
        publicKey: toBase64(publicKey),
        principal: principal,
        signature:  toBase64(signature),
    };

    return postMethod(path, data);
}

export async function requestRenewCertificate(publicKey, certificate, signatureOldKey, signatureNewKey ) {
    const path='certificates/renew';
    const data = {
        publicKey: toBase64(publicKey),
        certificate: toBase64(certificate),
        signatureOld: toBase64(signatureOldKey),
        signatureNew: toBase64(signatureNewKey),
    };

    return postMethod(path, data);
}
