import React  from 'react';
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStateProvider, { useGlobalState } from './gloabl';
import NavBar from './components/NavBar';
import RenewCertificates from './components/RenewCertificates';
import Error from './components/Error'
import Message from './components/Message';
import RevokeUnrevokeCertificates from "./components/RevokeUnrevokeCertificates";
import ConfirmationDialog from "./components/ConfirmationDialog";
import ReportCertificates from "./components/ReportCertificates";
import IssueCertificates from "./components/IssueCertificates";

function App() {
  const theme = createTheme();
  return (
    <div className='App'>
      <div className='mainBody'>
        <ThemeProvider theme={theme}>
          <GlobalStateProvider>
            <Router>
            <NavBar />
            <Inner/>
            </Router>
            <Error />
            <Message/>
            <ConfirmationDialog/>
          </GlobalStateProvider>
        </ThemeProvider>
      </div>
    </div>
  );
}

function Inner(){
  const [state, dispatch] = useGlobalState();
  const authenticated = state.user !== null ? true: false;
  return(<div>
    <Routes>
      { authenticated && <Route path = '/issue' element={<IssueCertificates />}/> }
      { authenticated && <Route path = '/renew' element={<RenewCertificates />}/> }
      { authenticated && <Route path = '/report' element={<ReportCertificates />}/> }
      { authenticated && <Route path = '/revoke' element={<RevokeUnrevokeCertificates />}/> }
    </Routes>
    </div>
  )
}

export default App


//File Upload:
//https://codesandbox.io/s/lgqwn?file=/src/FileUpload/FileUpload.tsx