import React, {useEffect, useState} from 'react';
import {
  Container,
  CssBaseline,
  Box,
  Grid,
  Button,
  Grow, RadioGroup, FormControlLabel, Radio, TextField
} from '@mui/material'
import {useGlobalState} from '../gloabl';
import {
  revokeCertificateByPrincipal,
  revokeCertificateByPublicKey,
  toBase64,
  unrevokeCertificateByPrincipal, unrevokeCertificateByPublicKey
} from "./support/ApiService";
import Typography from "@mui/material/Typography";
import {FileUpload} from "./FileUpload";

const DEVICE = "device";
const HOST = "host";
const PRINCIPAL = "PRINCIPAL";
const PUBLIC_KEY_BASE64 = "PUBLIC_KEY_BASE64";
const PUBLIC_KEY_FILE = "PUBLIC_KEY_FILE";

function isDevice(deviceOrHostValue){
  return deviceOrHostValue === DEVICE;
}

function createResultDescription(result, deviceOrHost) {
  let countCertificates = 0;
  let countKeys = 0;
  let resultDescription = "";
  if (result && result.revocations && Array.isArray(result.revocations)) {
    result.revocations.forEach(revokeEl => {
      if (revokeEl.publicKey)
        countKeys++;
      if (revokeEl.certificate)
        countCertificates++;
    });
    resultDescription = `${countCertificates} certificate(s) and ${countKeys} key(s) have been revoked for the ${deviceOrHost}`;
  } else if (result && result.undonerevocations && Array.isArray(result.undonerevocations)) {
    result.undonerevocations.forEach(revokeEl => {
      if (revokeEl.publicKey)
        countKeys++;
      if (revokeEl.certificate)
        countCertificates++;
    });

    resultDescription = `The revocation of ${countCertificates} certificate(s) and ${countKeys} key(s) is undone for the ${deviceOrHost}`;
  }

  if (result) {
    return <Typography>{resultDescription}</Typography>
  }
}

function getSearchParamLabel(deviceOrHost, searchBy) {
  if (searchBy === PUBLIC_KEY_BASE64) {
    return "Public Key (Base64)";
  } else if (searchBy === PUBLIC_KEY_FILE) {
    return "Public Key (File)";
  }
  return isDevice(deviceOrHost) ? "Device id" : "Host domain";
}

export default function RevokeUnrevokeCertificates() {
  const [state, dispatch] = useGlobalState();
  const [labelSearchParam, setLabelSearchParam] = useState("Device id");
  const [deviceOrHost, setDeviceOrHost] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [searchBy, setSearchBy] = useState(PRINCIPAL);
  const [publicKeyFile, setPublicKeyFile] = useState(undefined);

  const [result, setResult] = useState(undefined);

  useEffect(() => {
    setResult(undefined);
  }, [deviceOrHost, searchParam, searchBy]);
  useEffect(() => {
    setLabelSearchParam(getSearchParamLabel(deviceOrHost, searchBy));
  }, [deviceOrHost, searchBy]);
  useEffect(() => {
    setSearchParam("");
    setPublicKeyFile(undefined);
  }, [searchBy]);

  const fileUploadProp = {
    accept: '.pub',
    id: 'PublicKeyFileUpload',
    selectedFile: publicKeyFile,
    onFileUpload: (file, fileContent) => {
      setPublicKeyFile(file);
      setSearchParam(toBase64(fileContent));
    }
  }

  const revokeClicked = () => {
    setResult(undefined);

    if (!searchParam) {
      dispatch({"error": "All fields must be filled"})
      return true;
    }

    let confirmDlgConfig = {
      confirmationQuestion: (searchBy === PRINCIPAL) ? `Caution! ALL certificates and keys of the ${deviceOrHost} will be revoked. Are you sure you really want this?` :
        `Caution! The certificate with the public key of the ${deviceOrHost} will be revoked. Are you sure you really want this?`,
      confirmButtonLabel: "Revoke",
      cancelAction: () => {
        console.log("revoke cancel", deviceOrHost, searchParam);
      },
      okAction: doRevoke
    }
    dispatch({"confirmDlgConfig": confirmDlgConfig});
  }

  const unrevokeClicked = () => {
    setResult(undefined);

    if (!searchParam) {
      dispatch({"error": "All fields must be filled"})
      return true;
    }

    let confirmDlgConfig = {
      confirmationQuestion: (searchBy === PRINCIPAL) ? `Caution! The revocation for ALL certificates and keys of the ${deviceOrHost} will be undone. Are you sure you really want this?` :
        `Caution! The revocation of the certificate with the public key of the ${deviceOrHost} will be undone. Are you sure you really want this?`,
      confirmButtonLabel: "Undo revocation",
      cancelAction: () => {
        console.log("unrevoke cancel", deviceOrHost, searchParam);
      },
      okAction: doUnrevoke
    }
    dispatch({"confirmDlgConfig": confirmDlgConfig});
  }

  const doRevoke = async () => {
    dispatch({"message": "Communicating ...."});
    try {
      let result;
      if (searchBy === PRINCIPAL) {
        result = await revokeCertificateByPrincipal(deviceOrHost, searchParam);
      } else {
        result = await revokeCertificateByPublicKey(deviceOrHost, searchParam);
      }
      dispatch({"message": null});
      setResult(result);
    } catch (e) {
      dispatch({"message": null});
      let error = e.response;
      error.ErrorDescription = "Error revoking";
      dispatch({"error": error});
    }
  }

  const doUnrevoke = async () => {
    dispatch({"message": "Communicating ...."});
    try {
      let result;
      if (searchBy === PRINCIPAL) {
        result = await unrevokeCertificateByPrincipal(deviceOrHost, searchParam);
      } else {
        result = await unrevokeCertificateByPublicKey(deviceOrHost, searchParam);
      }
      dispatch({"message": null});
      setResult(result);
    } catch (e) {
      dispatch({"message": null});
      let error = e.response;
      error.ErrorDescription = "Error undo revocation";
      dispatch({"error": error});
    }
  }

  return (
    <>
      <h3>Revoke/Undo revocation of certificates and keys</h3>
      <CssBaseline/>
      <Container maxWidth="lg">
        <Grow in={true} timeout={400}>
          <Box sx={{flexGrow: 1, marginTop: "10px"}}>
            <Grid container spacing={2} style={{textAlign: "left"}}>
              <Grid item xs={3}>
                Please choose the type:
              </Grid>
              <Grid item xs={9}>
                <RadioGroup row={true}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={deviceOrHost}
                            onChange={event => setDeviceOrHost(event.target.value)}>
                  <FormControlLabel value={DEVICE} control={<Radio/>} label="Device"/>
                  <FormControlLabel value={HOST} control={<Radio/>} label="Host"/>
                </RadioGroup>
              </Grid>

              {deviceOrHost &&
                <>
                  <Grid item xs={3}>
                    Revoke By:
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup row={true}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="search-parameter-buttons-group"
                                value={searchBy}
                                onChange={event => setSearchBy(event.target.value)}>
                      <FormControlLabel value={"PRINCIPAL"} control={<Radio/>} label="Principal"/>
                      <FormControlLabel value={"PUBLIC_KEY_BASE64"} control={<Radio/>} label="Public Key (Base64)"/>
                      <FormControlLabel value={"PUBLIC_KEY_FILE"} control={<Radio/>} label="Public Key (File)"/>
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    {labelSearchParam}
                  </Grid>
                  <Grid item xs={9}>
                    {searchBy === PUBLIC_KEY_FILE &&
                      <FileUpload {...fileUploadProp}/>
                    }
                    {searchBy !== PUBLIC_KEY_FILE &&
                      <TextField inputProps={{style: {fontSize: 12, fontFamily: 'Monospace'}}}
                                 value={searchParam}
                                 onChange={(e) => setSearchParam(e.target.value)}
                                 fullWidth
                                 label={labelSearchParam}/>
                    }
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={9}>
                    <Button variant="contained" onClick={() => revokeClicked()}>
                      Revoke
                    </Button>

                    <Button variant="contained" style={{marginLeft: "10px"}} onClick={() => unrevokeClicked()}>
                      Undo revocation
                    </Button>
                    {createResultDescription(result, deviceOrHost)}
                  </Grid>
                </>
              }
            </Grid>
          </Box>
        </Grow>
      </Container>
    </>
  );
}

