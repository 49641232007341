import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useGlobalState } from '../gloabl';
import {Dialog, DialogTitle, Grid} from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


export default function ConfirmationDialog() {
    const [state, dispatch] = useGlobalState();
    const {confirmDlgConfig} = state;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        dispatch({confirmationQuestion:undefined})
        setOpen(false);
    }

    useEffect(() =>{
        if(confirmDlgConfig){
            setOpen(true)
        }
        else{
            setOpen(false)
        }
    },[confirmDlgConfig])

    const okClicked = () =>
    {
        setOpen(false);
        confirmDlgConfig.okAction();

    }

    const cancelClicked = () =>
    {
        setOpen(false);
        confirmDlgConfig.cancelAction();
    }

    return (
        <div>
            <Dialog  open={open}   onClose={handleClose} >
                <DialogTitle>Please confirm!</DialogTitle>
                <DialogContent>{confirmDlgConfig && confirmDlgConfig.confirmationQuestion}</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={okClicked} >
                        {confirmDlgConfig && confirmDlgConfig.confirmButtonLabel}
                    </Button>
                    <Button style={ {marginLeft: "10px"} } variant="contained" onClick={cancelClicked} >
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}