import React, { useEffect, useState } from 'react';
import  { Auth, Hub } from 'aws-amplify';
import { useGlobalState } from '../gloabl';
import { Button } from '@mui/material';
import { castCgnUserToDormakabaId } from '../Utils';
import configuration from "./../config.json";

export default function Authenticate() {
  const provider = configuration.provider || "dormakaba-ID";
  const [state, dispatch] = useGlobalState();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const signIn = (user) => {
    setUser(user)
    dispatch({user:user})
  }
  const signOut = () => {
    setUser(null);
    dispatch({user:null})
  }
  useEffect(()=>{
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          signIn(data);
          break;
        case "signOut":
          signOut();
          break;
        case "customOAuthState":
          signOut()
      }
    });
  },[]);

  useEffect(()=>{
    Auth.currentAuthenticatedUser()
      .then(user => {
        signIn(user);
      })
      .catch(() => console.log('unauthenticated'));
  
  },[])
  useEffect(() => {
    Auth.currentSession().then(s => {
      /*console.log(s.accessToken.jwtToken)
      console.log(s.idToken.jwtToken)*/
      const userId = castCgnUserToDormakabaId(s.accessToken.payload.username)
      const email = s.idToken.payload.email
      setUserId(userId)
      setEmail(email)
    }
      )
  },[user])
  return (
    <div>
      {!user && <Button  color="success" variant="contained" onClick={() => Auth.federatedSignIn({ provider: provider })}>Sign In </Button>}
      { user!==null && <Button  color="error" variant="contained" onClick={() => Auth.signOut()}>Sign Out</Button> }  {email || ''}
      { userId !== null && ` (${userId})`}
    </div>
  );
}
