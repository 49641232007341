import React, { useState } from 'react';
import {
  Container,
  CssBaseline,
  Box,
  Grid,
  Button,
  Grow,
} from '@mui/material'
import { useGlobalState } from '../gloabl';
import {requestCertificate} from "./support/ApiService";
import {DeviceOrHostChooser, isDevice} from "./DeviceOrHostChooser";
import {FileUpload} from "./FileUpload";
import {SignatureInfo} from "./SignatureInfo";
import {downloadCertificateForPublicKeyFile, getCertFileName} from "./support/Downloader";
export default function IssueCertificates() {
  const [state, dispatch] = useGlobalState();
  const [deviceOrHost, setDeviceOrHost] = useState("");
  const [deviceId, setdeviceId] = useState("");
  const [hostDomain, setHostDomain] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [publicKeyFile, setPublicKeyFile] = useState(undefined);
  const [signatureFile, setSignatureFile] = useState("");
  const [signature, setSignature] = useState("");

  const fileUploadProp = {
        accept: '.pub',
        id: 'PublicKeyFileUpload',
        selectedFile: publicKeyFile,
        onFileUpload: (file, fileContent) => {
            setPublicKeyFile (file);
            setPublicKey(fileContent);
        }
    }

  const submit = () =>{

      if (isDevice(deviceOrHost) ? !deviceId : !hostDomain) {
          dispatch({"error":"All fields must be filled"})
      }

      if (!publicKey || !signature) {
          dispatch({"error": "All fields must be filled"})
      }

      dispatch({"message": "Fetching the certificate"});
      requestCertificate(deviceOrHost, publicKey, isDevice(deviceOrHost) ? deviceId : hostDomain, signature).then((r)=> {
          dispatch({"message":null});
          if ( !r.certificateData){
              dispatch({"error": `Error fetching the certificate ${r}`});
          }
          downloadCertificateForPublicKeyFile(r.certificateData, publicKeyFile)

      }).catch( e => {
          dispatch({"message":null});
          let error = e.response;
          error.ErrorDescription = "Error fetching the certificate";
          dispatch({"error": error});
      });
  }

  const signatureFileUploadProps = {
        accept: '.sig',
        id: 'SignatureFileUpload',
        selectedFile: signatureFile,
        onFileUpload: (file, fileContent) => {
            setSignatureFile (file);
            setSignature(fileContent);
        }
  }

  const getSignatureInfoTitle = () => {
      return isDevice(deviceOrHost) ?
          'Signature of public key and device id' :
          'Signature of public key and host domain';
  }

  const getSignatureInfoContent = () => {
      return isDevice(deviceOrHost) ?
          'The following string must be signed with your private key: public key file in base64 format, followed by a "#"-character and then the device id.\r\nA signature file can be created with ssh-keygen as in the following example:\r\necho -n "$(base64 -w 0 <public-key-file>)#<device-id>" | ssh-keygen -Y sign -f <private-key-file> -n issue-ssh-key.dormakaba.com > output.sig':
          'The following string must be signed with your private key: public key file in base64 format, followed by a "#"-character and then the host domain.\r\nA signature file can be created with ssh-keygen as in the following example:\r\necho -n "$(base64 -w 0 <public-key-file>)#<host-domain>" | ssh-keygen -Y sign -f <private-key-file> -n issue-ssh-key.dormakaba.com > output.sig';
  }

  return (
    <>
    <h3>Issue certificates</h3>
    <CssBaseline />
      <Container maxWidth="lg">
       <Grow  in={true} timeout={400}>
       <Box sx={{ flexGrow: 1 , marginTop: "10px"}}>
         <Grid container spacing={2} style={{textAlign:"left"}}>

             <DeviceOrHostChooser deviceId={deviceId} hostDomain={hostDomain}
                                  deviceOrHostValue={deviceOrHost} onChangeDeviceOrHost={value=>setDeviceOrHost(value)}
                                  onChangeDeviceId={deviceId=>setdeviceId(deviceId)}
                                  onChangeHostDomain={hostDomain=>setHostDomain(hostDomain)} />

             {deviceOrHost && <>
             <Grid item xs={3}>
                 Public key file
             </Grid>
             <Grid item xs={9} >
                 <FileUpload {...fileUploadProp}/>
             </Grid>

             <Grid item xs={3}>
                 Signature
                 <SignatureInfo title={getSignatureInfoTitle()} content={getSignatureInfoContent()} />
             </Grid>
             <Grid item xs={9} >
                 <FileUpload {...signatureFileUploadProps}/>
             </Grid>
            </>}

             {deviceOrHost &&
             <>
               <Grid item xs={3}></Grid>
               <Grid item xs={9}>
                 <Button variant="contained" onClick={() => submit()} >
                    Generate certificate {getCertFileName(publicKeyFile)}
                 </Button>
               </Grid>
             </>
         }


        </Grid>
     </Box>
     </Grow>
      </Container>
    </>
  );
}

