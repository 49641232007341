import React from 'react'
import { Box, Typography } from '@mui/material'
import CloudIcon from '@mui/icons-material/Cloud';
import clsx from 'clsx'
import {makeStyles} from '@mui/styles'

const useStyle = makeStyles({
    root: {
        cursor: 'pointer',
        textAlign: 'left',
        //display: 'flex',
        '&:hover p,&:hover svg,& img': {
            opacity: 1,
        },
        '& p, svg': {
            opacity: 0.4,
        },
        '&:hover img': {
            opacity: 0.3,
        },
    },
    noMouseEvent: {
        pointerEvents: 'none',
    },
    iconText: {
       // display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'left',
        position: 'absolute',
    },
    hidden: {
        display: 'none',
    },
    onDragOver: {
        '& img': {
            opacity: 0.3,
        },
        '& p, svg': {
            opacity: 1,
        },
    },
})

async function readFileContent(file) {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onloadend =  e => {
            resolve(fileReader.result);
        };
        fileReader.readAsText(file);
    })
}

function getHoverLabel(accept) {
    return `Click or drag your ${accept} file to upload`;
}

export const FileUpload = ({
                              accept,
                              id,
                              selectedFile,
                              dropLabel = 'Drop file here',
                              width = '900px',//'600px',
                              height = '100px',// '100px',
                              backgroundColor = '#fff',
                              onFileUpload,
                          }) => {
    const classes = useStyle()
    const [labelText, setLabelText] = React.useState(getHoverLabel(accept))
    const [isDragOver, setIsDragOver] = React.useState(false)
    const [isMouseOver, setIsMouseOver] = React.useState(false)
    const stopDefaults = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true)
        },
        onMouseLeave: () => {
            setIsMouseOver(false)
        },
        onDragEnter: (e) => {
            stopDefaults(e)
            setIsDragOver(true)
            setLabelText(dropLabel)
        },
        onDragLeave: (e) => {
            stopDefaults(e)
            setIsDragOver(false)
            setLabelText(getHoverLabel(accept))
        },
        onDragOver: stopDefaults,
        onDrop: (e) => {
            stopDefaults(e)
            setLabelText(getHoverLabel(accept))
            setIsDragOver(false)

            if (e?.dataTransfer?.files !== null && e?.dataTransfer?.files.length > 0)
            {
                readFileContent(e.dataTransfer.files[0]).then((fileContent)=>{
                    onFileUpload(e.dataTransfer.files[0], fileContent);
                });
            }
        },
    }

    const handleChange = (event) => {
        if (
            event.target.files !== null &&
            event.target?.files?.length > 0
        ) {
            readFileContent(event.target.files[0]).then((fileContent)=>{
                onFileUpload(event.target.files[0], fileContent);
            });
        }

    }

    return (
        <>
            <input
                onChange={handleChange}
                accept={accept}
                className={classes.hidden}
                id={`${id}Input`}
                type="file"
            />

            <label
                id={`${id}Label`}
                htmlFor={`${id}Input`}
                {...dragEvents}
                className={clsx(classes.root, isDragOver && classes.onDragOver)}
            >

                <Box
                    width={width}
                    height={height}
                    bgcolor={backgroundColor}
                    className={classes.noMouseEvent}
                >
                    {
                        <>

                            <Box
                                height={height}
                                width={width}
                                className={classes.iconText}
                            >
                                {selectedFile && <Typography> {selectedFile.name} </Typography>}
                                <CloudIcon fontSize="large" />
                                <Typography>{labelText}</Typography>

                            </Box>
                        </>
                    }
                </Box>

            </label>
        </>
    )
}
