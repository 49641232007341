import {FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField} from "@mui/material";
import React from "react";

const DEVICE = "device";
const HOST = "host";

export function isDevice(deviceOrHostValue)
{
    return deviceOrHostValue === DEVICE;
}
export const DeviceOrHostChooser = ({deviceOrHostValue,
    deviceId, hostDomain, onChangeDeviceOrHost, onChangeDeviceId, onChangeHostDomain}) => {

    //Do not change these constants, they are also used in the api requests


    return (
        <>
            <Grid item xs={3}>
        Please choose the type:
    </Grid>
    <Grid item xs={9} >
        <FormControl>
            <RadioGroup row={true}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={deviceOrHostValue}
                        onChange={event=> onChangeDeviceOrHost(event.target.value)}>
                <FormControlLabel value={DEVICE} control={<Radio />} label="Device" />
                <FormControlLabel value={HOST} control={<Radio />} label="Host" />
            </RadioGroup>
        </FormControl>
    </Grid>

    {deviceOrHostValue === DEVICE &&
    <>
        <Grid  item xs={3}>
            Device id
        </Grid>
        <Grid item xs={9}>
            <TextField inputProps={{style: {fontSize: 12,fontFamily: 'Monospace' }}}  multiline minRows={1} maxRows={1}
                       value={deviceId} onChange={(e)=> onChangeDeviceId(e.target.value)} fullWidth  label="Device id"/>
        </Grid>
    </>
    }

    {deviceOrHostValue === HOST &&
    <>
        <Grid item xs={3}>
            Host domain
        </Grid>
        <Grid item xs={9}>
            <TextField inputProps={{style: {fontSize: 12,fontFamily: 'Monospace' }}}  multiline minRows={1} maxRows={1}
                       value={hostDomain} onChange={(e)=> onChangeHostDomain(e.target.value)} fullWidth  label="Host domain"/>
        </Grid>
    </>
    }
    </>);
}
