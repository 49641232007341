import React, { useEffect, useState } from 'react';
import {
  Container,
  CssBaseline,
  Box,
  Grid,
  Button,
  Grow,
} from '@mui/material'
import { useGlobalState } from '../gloabl';
import {getReport} from "./support/ApiService";
import {DataGrid} from "@mui/x-data-grid";
import {DeviceOrHostChooser, isDevice} from "./DeviceOrHostChooser";
export default function ReportCertificates() {
  const [state, dispatch] = useGlobalState();
  const [deviceOrHost, setDeviceOrHost] = useState("");
  const [deviceId, setdeviceId] = useState("");
  const [hostDomain, setHostDomain] = useState("");
  const [result, setResult] = useState(undefined);

  useEffect(() =>{
    setResult(undefined);
  },[deviceId, hostDomain, deviceOrHost]);

  const getReportClicked = () => {
    setResult(undefined);

    if (isDevice(deviceOrHost) ? !deviceId : !hostDomain) {
      dispatch({"error":"All fields must be filled"})
      return true;
    }

    dispatch({"message":"Communicating..."})
    getReport(deviceOrHost, isDevice(deviceOrHost) ? deviceId: hostDomain )
        .then((r)=> {
        dispatch({"message":null});
        setResult(r);
      }).catch( e => {
        dispatch({"message":null});
        let error = e.response;
        error.ErrorDescription = "Error fetching the report";
        dispatch({"error": error});
      });

  }

  const createResultTable= () => {

    if (result && result.certificates && Array.isArray(result.certificates))
    {
        let id =0;
       let rows =result.certificates.map(cert=> (
           { id: id++,
               keyId: cert.keyId,
            principal: cert.principal,
            publicKeyCreated: cert.publicKey.created,
             publicKeyCreatedBy: cert.publicKey.createdBy,
             publicKeyRevoked: cert.publicKey.revoked? "true": "false" ,
             publicKeyRevokedBy: cert.publicKey.revokedBy,
             publicKeyRevokedTime: cert.publicKey.revokedTime,
             publicKeyUndoRevokedBy: cert.publicKey.undoRevokedBy,
             publicKeyUndoRevokedTime: cert.publicKey.undoRevokedTime,
             validAfter: cert.validAfter,
             validBefore: cert.validBefore,
             created: cert.created,
             revoked: cert.revoked ? "true" : "false",
             revokedBy: cert.revokedBy,
             revokedTime: cert.revokedTime,
             undoRevokedBy: cert.undoRevokedBy,
             undoRevokedTime: cert.undoRevokedTime,

           }));


       let dataGridColumns = [
           {field: 'keyId', headerName: 'KeyId', width: 300},
           {field: 'principal', headerName: 'Principal'},
           {field: 'validAfter', headerName: 'Valid after',  width: 200},
           {field: 'validBefore', headerName: 'Valid before',  width: 200},
           {field: 'created', headerName: 'Created',  width: 200},
           {field: 'revoked', headerName: 'Revoked'},
           {field: 'revokedBy', headerName: 'Revoked By', width: 200},
           {field: 'revokedTime', headerName: 'Revoked time', width: 200},
           {field: 'undoRevokedBy', headerName: 'Undo revoked by', width: 200},
           {field: 'undoRevokedTime', headerName: 'Undo revoked time', width: 200},
           {field: 'publicKeyCreated', headerName: 'Public key created', width: 200},
           {field: 'publicKeyCreatedBy', headerName: 'Public key created by', width: 200},
           {field: 'publicKeyRevoked', headerName: 'Public key revoked'},
           {field: 'publicKeyRevokedBy', headerName: 'Public key revoked by', width: 200},
           {field: 'publicKeyRevokedTime', headerName: 'Public key revoked Time', width: 200},
           {field: 'publicKeyUndoRevokedBy', headerName: 'Public key undo revoked by', width: 200},
           {field: 'publicKeyUndoRevokedTime', headerName: 'Public key undo revoked time', width: 200},
       ];

      return ( <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid columns={dataGridColumns} rows={rows}/>
            </Box>);
    }
  }

  return (
    <>
    <h3>Report</h3>
    <CssBaseline />
      <Container maxWidth="lg">
       <Grow  in={true} timeout={400}>
       <Box sx={{ flexGrow: 1 , marginTop: "10px"}}>
         <Grid container spacing={2} style={{textAlign:"left"}}>

             <DeviceOrHostChooser deviceId={deviceId} hostDomain={hostDomain}
                                  deviceOrHostValue={deviceOrHost} onChangeDeviceOrHost={value=>setDeviceOrHost(value)}
                                  onChangeDeviceId={deviceId=>setdeviceId(deviceId)}
                                  onChangeHostDomain={hostDomain=>setHostDomain(hostDomain)} />
         {deviceOrHost &&
             <>
               <Grid item xs={3}></Grid>
               <Grid item xs={9}>
                 <Button variant="contained" onClick={() => getReportClicked()} >
                  Report
                 </Button>
               </Grid>
             </>
         }
           {createResultTable()}

        </Grid>
     </Box>
     </Grow>
      </Container>
    </>
  );
}

