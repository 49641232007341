import * as React from "react";

const initialGlobalState = {
    error:  null,
    message: null,
    user: null
  };
  const GlobalStateContext = React.createContext(initialGlobalState);
  const DispatchStateContext = React.createContext(undefined);
  
  const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(
      (state, newValue) => ({ ...state, ...newValue }),
      initialGlobalState
    );
    return (
      <GlobalStateContext.Provider value={state}>
        <DispatchStateContext.Provider value={dispatch}>
          {children}
        </DispatchStateContext.Provider>
      </GlobalStateContext.Provider>
    );
  };
  
  const useGlobalState = () => [
    React.useContext(GlobalStateContext),
    React.useContext(DispatchStateContext)
  ];

  export {useGlobalState,GlobalStateProvider}
  export default GlobalStateProvider