import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useGlobalState } from '../gloabl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Message() {
    const [state, dispatch] = useGlobalState();
    const {message} = state;
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        dispatch({message:null})
        setOpen(false);
    }

    useEffect(() =>{
        if(message !== null){
            setOpen(true)
        }
        else{
            setOpen(false)
        }
    },[message])

    return (
        <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {message || ''}
            </Typography>
            </Box>
        </Modal>
        </div>
    );
}